<template>
  <div>
    <v-card>
      <v-card-title class="d-flex bd-highlight align-center flex-wrap">
        Rentabilidade Mensal
      </v-card-title>
      <v-divider class="mt-0"></v-divider>

      <v-card-text class="d-flex align-center flex-wrap mt-5 pb-0">
        <v-row class="mb-3">
          <v-row class="mb-3">
            <v-col
              cols="12"
              md="4"
              class="pb-1"
            >
              <v-autocomplete
                v-model="reference"
                :items="referenceList"
                item-text="name"
                item-value="name"
                label="Periodo"
                outlined
                dense
                hide-details
                cols="12"
                md="3"
                sm="4"
                lg="3"
                class="pb-1"
                @input="onInput"
              />
            </v-col>
          </v-row>
        </v-row>
      </v-card-text>

      <v-data-table
        :headers="headers"
        :items="dataTable"
        loading-text="Carregando dados..."
        :loading="loading"
        disable-sort
      >
        <template #[`item.comission_total`]="{ item }">
          {{ Number(item.comission_total).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}
        </template>
        <template #[`item.commission_provision`]="{ item }">
          {{ Number(item.commission_provision).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}
        </template>
        <template #[`item.percent`]="{ item }">
          {{ item.percent ? `${item.percent}%` : 'Not value' }}
        </template>
        <template
          v-slot:no-data
        >
          <span :class="classNoDataText">{{ noDataText }}</span>
        </template>
        <template
          v-slot:group.header="{items}"
        >
          <td
            colspan="10"
          >
            {{ items[0].reference }}
          </td>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import axiosIns from '@/plugins/axios'

export default {
  data() {
    return {
      referenceList: [],
      reference: '',
      loading: false,
      classNoDataText: '',
      noDataText: '',
      dataTable: [],
      headers: [
        { text: 'NOME', value: 'name' },
        { text: 'COMISS. TOTAL', value: 'comission_total' },
        { text: 'COMISS. PROVISÃO', value: 'commission_provision' },
        { text: 'PERCENTUAL', value: 'percent', align: 'center' },
        { text: 'REFERÊNCIA', value: 'reference', align: 'center' },
        { text: 'VENDAS', value: 'sales', align: 'center' },
        { text: 'MES/ANO', value: 'mesano', align: 'center' },
      ],

    }
  },

  async created() {
    await this.getReferenceList()
    await this.getFilterItems()
    await this.getReferenceList()
  },

  methods: {
    async onInput() {
      await this.getFilterItems()
    },
    async getReferenceList() {
      await axiosIns.get('/api/v1/integration/contract/list_references').then(res => {
        this.referenceList = res.data.data.map(item => item.reference)
      })
    },

    async getFilterItems() {
      this.loading = true
      this.noDataText = 'NENHUM REGISTRO ENCONTRADO'
      this.classNoDataText = ''

      if (!this.reference) {
        // eslint-disable-next-line prefer-destructuring
        this.reference = this.referenceList[0]
      }
      const filter = {
        reference: this.reference,
      }

      await axiosIns.post('/api/v1/integration/contract/portfolio_returnity', filter)
        .then(res => {
          this.dataTable = res.data.data
          this.loading = false

          // this.pageOptions.itemsPerPage = res.data.per_page
          // this.pageOptions.pageCount = res.data.last_page
        })
      this.loading = false
    },
  },
}
</script>
<style>
  .colorWarning {
    color: #ff9800 !important;
  }
  </style>
